import { render, staticRenderFns } from "./MModal.vue?vue&type=template&id=47e6bb0c&scoped=true&"
import script from "./MModal.vue?vue&type=script&lang=js&"
export * from "./MModal.vue?vue&type=script&lang=js&"
import style0 from "./MModal.vue?vue&type=style&index=0&id=47e6bb0c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e6bb0c",
  null
  
)

export default component.exports